import styled from "styled-components";
import {lightGrey} from "colors";

export const FinalCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${lightGrey};
  scroll-snap-align: start;
  padding: 75px;
  .MuiTypography-h1{
    font-size: 2.5rem;
  }
  
  @media screen and (max-width: 640px) {
      .MuiTypography-h1{
        font-size: 2rem;
      }
      padding: 20px;
      text-align: center;
  }
`;
