import {Testimonial} from "./styled/Testimonial"
import React from "react";
import Typography from "@material-ui/core/Typography";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import GradeIcon from '@material-ui/icons/Grade';

export default ({by, testimonial, active}) => (
    <Testimonial active={active}>
        <Typography variant={"body1"} align={"center"}><FormatQuoteIcon/></Typography>
        <Typography variant={"h2"}>
            "{testimonial}"
        </Typography>
        <Typography variant={"body1"} align={"center"} color={"secondary"} >{[1,1,1,1,1].map((x, index) => <GradeIcon key={index}/>)}</Typography>
        <Typography variant={"body1"}>{by}</Typography>
    </Testimonial>
);

