import styled from "styled-components";
import {lightGrey, blue} from "../../colors";


export const Testimonial = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  max-width: 700px;
  white-space: pre-wrap;
  margin: 0 20px;
  flex-direction: column;
      background-color: ${blue};
  color: ${lightGrey};
   @media screen and (max-width: 640px) {
     font-size: 3rem;
     margin: 0 10px;
     .MuiTypography-h2{
      font-size: 1.3rem;
      margin: 20px 0;
     }
     
   @media screen and (max-width: 360px) {
     .MuiTypography-h2{
      font-size: 1.2rem;
      margin: 1.5rem 0;
   }
     
`;
