import styled from "styled-components";

export const OssStrip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 75px;
  
  @media screen and (max-width: 960px) {
    padding: 2rem;
  }
  .MuiTypography-h1 {
      font-size: 2.5rem;
  }
`;

export const OssImage = styled.div` 
  text-align: center;
  padding: 0 100px;
  
  img {
   animation: heartbeat 1.5s infinite;
   width: 150px;
  }
  
  @media screen and (max-width: 960px) {
     margin: 2rem;
     padding: initial;
   }
  
  @keyframes heartbeat {
  0% {
    transform: scale( 1 );    
  }
  20% {
    transform: scale( 1.02 ) 
  } 
  40% {
    transform: scale( 1.03 ) 
  }
}
`;


export const OssContent = styled.div` 
  display: flex;
  align-items: center;
  
    
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  
`;


export const OssWriteup = styled.div` 
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  
  @media screen and (max-width: 400px) {
    .MuiTypography-h2 {
      font-size: 1.2rem;
      margin: 2rem 0;
    }
  }
  
`;

