import Testimonial from "./Testimonial"
import React, {useState} from "react";
import {
    TestimonialNav,
    Testimonials, TestimonialsContent,
    TestimonialsDot,
    TestimonialsDots, TestimonialsHeader,
    TestimonialsStrip
} from "./styled/Testimonials";
import Typography from "@material-ui/core/Typography";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";


export default () => {
    const [active, setActive] = useState(0);
    const testimonials = getTestimonials();

    function next() {
        if (active < testimonials.length - 1) {
            setActive(active + 1);
        }
    }

    function previous() {
        active > 0 && setActive(active - 1)
    }


    return <>
        <TestimonialsStrip>
            <TestimonialsHeader>
                <Typography variant={"h1"} align={"center"}>What people are saying in the Chrome store</Typography>
            </TestimonialsHeader>
            <TestimonialsContent>
                <TestimonialNav disabled={active === 0}><ChevronLeft fontSize={"inherit"} onClick={previous}
                                                                     color={active === 0 ? "disabled" : "inherit"
                                                                     }/>
                </TestimonialNav>
                <Testimonials>
                    <Testimonial active={true} by={testimonials[active].by}
                                 testimonial={testimonials[active].testimonial}
                    />
                    <TestimonialsDots>
                        {testimonials.map((t, i) => <TestimonialsDot active={active === i} key={i}
                                                                     onClick={() => setActive(i)}/>)}
                    </TestimonialsDots>
                    <Box m="2rem">
                        <Link
                            color={"initial"}
                            component={props => <Button {...props} variant="contained" color={"secondary"}/>}
                            href={"https://chrome.google.com/webstore/detail/unpinterested/gefaihkenmchjmcpcbpdijpoknfjpbfe"}
                        >
                            See all reviews
                        </Link>
                    </Box>
                </Testimonials>
                <TestimonialNav disabled={active === testimonials.length - 1}>
                    <ChevronRight fontSize={"inherit"}
                                  onClick={next}
                                  color={active === testimonials.length - 1 ? "disabled" : "inherit"}
                    />
                </TestimonialNav>
            </TestimonialsContent>
        </TestimonialsStrip>

    </>

};

function getTestimonials() {
    return [
        {
            by: "Linda Moskowitz",
            testimonial: "I don't know who Sello Mkantjwa is, but they are my new hero. This makes image search productive again"
        },
        {
            by: "Brad Korff",
            testimonial: "This is brilliant!  Thanks"
        },
        {
            by: "AntiSane Jim",
            testimonial: "I have wanted something like this for ages! So simple, yet so wonderful, THANK YOU!"
        },
        {
            by: "Carina Merrick",
            testimonial: "I just installed this after trying another extension that did nothing. This worked perfectly right away."
        },
        {
            by: "Kristina Unduine",
            testimonial: `This is wonderful, a fantastic improvement for browsing! This extension has decluttered my search results.`

        }]
}
