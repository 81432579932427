import styled from 'styled-components';
import {red} from "../../colors";

export default styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    
     @media screen and (max-width: 640px) {
         .MuiTypography-h2{
              font-size: 1.3rem;
           }
     }
     
     @media screen and (max-width: 360px) {
      .MuiTypography-h1{
         font-size: 2rem;
      }
    } 
`;

export const MainImage = styled.div`
  justify-content: center;
  padding: 1.5rem;
  box-sizing: border-box;
  text-align: center;
  img, video{
    width: 500px;
    max-width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }
  
   @media screen and (max-width: 640px) {
    padding: initial;
   } 
  
`;

export const Cta = styled.div`
display: flex;
  margin: 2rem;
  justify-content: center;
    
  .MuiTypography-textPrimary .MuiButton-label {
    color: ${red};
  }
  
  .MuiButton-containedPrimary .MuiButton-label {
     color: #ffffff;
  }
  
  .MuiButton-containedSecondary .MuiButton-label {
    color: #000
  }
  
  a:hover{
    text-decoration: none;
  }
  
  
  @media screen and (max-width: 640px) {
    margin: 2rem 1rem;
  }  
  
  @media screen and (max-width: 360px) {
      .MuiButtonBase-root{
         font-size: 0.7rem;
      }
    } 
`;

export const FeaturedCta = styled(Cta)`
display: flex;
  margin: 2rem;
  justify-content: center;
  
  @media screen and (max-width: 640px) {
    margin: 2.5rem 0 ;
  }  
`;


export const Logo = styled.div`
text-align: center;
 margin: 3rem 0 0;
`;
