import React from 'react';
import {Button, CssBaseline, createMuiTheme, ThemeProvider} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {red, yellow} from './colors';
import Main, {Cta, FeaturedCta, Logo, MainImage} from "./components/styled/Main";
import {FeatureSection, FeatureSectionImage, FeatureSectionWriteup} from "./components/styled/FeatureSection";
import Container from "@material-ui/core/Container";
import GithubIcon from "@material-ui/icons/GitHub";
import Testimonials from "./components/Testimonials";
import {FinalCta} from "./components/styled/FinalCta";
import {OssContent, OssImage, OssStrip, OssWriteup} from "./components/styled/OSSStrip";
import Box from "@material-ui/core/Box";
import {Footer} from "./components/styled/Footer";
import Link from "@material-ui/core/Link";
import {PhoneHide, PhoneOnly} from "./components/styled/PhoneOnly";

function App() {

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: red
            },
            secondary: {
                main: yellow
            },
            background: {
                default: "#fff"
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    "padding": "10px 20px"
                }
            },
            MuiTypography: {
                h1: {
                    fontSize: "3rem",
                    margin: "1rem",
                },
                h2: {
                    fontSize: "1.5rem",
                    margin: "1rem"
                },
                body1: {
                    fontSize: "1.1rem"
                }
            }

        }
    });
    theme.spacing();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <Container>
                    <Main>
                        <Logo align={"center"}>
                            <img src={"/unpinterested.svg"} width={100} alt={"Unpinterested! logo"}/>
                        </Logo>
                        <Typography variant="h1" align={"center"}>
                            experience freedom
                        </Typography>

                        <Typography variant="h2" align={"center"}>
                            Unpinterested! is a Chrome extension that allows you
                            <PhoneHide>
                                <br/>
                            </PhoneHide>
                            <PhoneOnly>{' '}</PhoneOnly>to remove Pinterest from Google search
                            results
                        </Typography>
                        <MainImage>
                            <video autoPlay loop muted playsInline>
                                <source src={"image-search.webm"} type="video/webm"/>
                                <source src={"image-search.mp4"} type="video/mp4"/>
                            </video>
                        </MainImage>
                        <Cta>
                            <Link
                                component={props => <Button {...props} variant="contained" color={"primary"}/>}
                                href={"https://chrome.google.com/webstore/detail/unpinterested/gefaihkenmchjmcpcbpdijpoknfjpbfe"}
                            >
                                Install from the Chrome Store
                            </Link>
                        </Cta>
                    </Main>
                </Container>
                <Container>
                    <FeatureSection>
                        <FeatureSectionImage imageMaxWidth={"250px"}>
                            <img src={"button_big.svg"} alt="removing pinterst results"/>
                        </FeatureSectionImage>
                        <FeatureSectionWriteup>
                            <Typography variant="h1" align={"center"}>Simple and unobtrusive
                            </Typography>
                            <Typography variant={"body1"}>
                                Unpinterested! aims to be as simple to use as impossible. With just the flick of a
                                switch,
                                you can immediately remove Pinterest from your search results. If you would like to see
                                Pinterest results again, just flick the switch in the other direction.
                            </Typography>
                        </FeatureSectionWriteup>
                    </FeatureSection>
                    <FeatureSection reverse>
                        <FeatureSectionImage>
                            <img src={"hidden-image-search.png"} alt="removing pinterst results"/>
                        </FeatureSectionImage>
                        <FeatureSectionWriteup>
                            <Typography variant="h1" align={"center"}>Dont just hide results, block
                                them from the source.
                            </Typography>
                            <Typography variant={"body1"}>
                                Instead of removing Pinterest from the page after they have been retrieved,
                                Unpinterested! instructs Google to not return any results from Pinterest.
                                This is important because that means any space that would
                                have otherwise been taken up by Pinterest results is now occupied by other
                                sites.
                            </Typography>
                            <FeaturedCta>
                                <Link
                                    component={props => <Button {...props} variant="contained" color={"primary"}/>}
                                    href={"https://chrome.google.com/webstore/detail/unpinterested/gefaihkenmchjmcpcbpdijpoknfjpbfe"}
                                >
                                    Block Pinterest now
                                </Link>
                            </FeaturedCta>
                        </FeatureSectionWriteup>
                    </FeatureSection>
                    <FeatureSection>
                        <FeatureSectionImage>
                            <video autoPlay loop muted playsInline>
                                <source src={"all-search.webm"} type="video/webm"/>
                                <source src={"all-search.mp4"} type="video/mp4"/>
                            </video>
                        </FeatureSectionImage>
                        <FeatureSectionWriteup>
                            <Typography variant="h1" align={"center"}>
                                Take full control
                            </Typography>
                            <Typography variant={"body1"}>
                                By default, Unpinterested! will block Pinterest when you are searching for images.
                                For total control, you can choose to block Pinterest from ALL searches.
                            </Typography>
                        </FeatureSectionWriteup>
                    </FeatureSection>
                </Container>
                <Testimonials/>
                <Box>
                    <OssStrip>
                        <Typography variant="h1" align={"center"}>
                            One for the community
                        </Typography>
                        <OssContent>
                            <OssImage>
                                <img src={"lock.svg"} alt={"lock heart"}/>
                            </OssImage>
                            <OssWriteup>
                                <Typography variant={"h2"}>
                                    Unpinterested! is completely open source, so if you want to see exactly how it
                                    works or want
                                    to contribute to the project, you are more than welcome to do so.
                                </Typography>
                            </OssWriteup>
                        </OssContent>
                        <Cta>
                            <Link
                                component={props => <Button {...props} variant="text" color={"primary"}
                                                            startIcon={<GithubIcon/>}/>}
                                href={"https://chrome.google.com/webstore/detail/unpinterested/gefaihkenmchjmcpcbpdijpoknfjpbfe"}
                            >
                                Explore the source code
                            </Link>
                        </Cta>
                    </OssStrip>
                </Box>
                <FinalCta>
                    <div align={"center"}>
                        <img src={"/unpinterested.svg"} width={100} alt={"Unpinterested! logo"}/>
                    </div>
                    <Typography variant={"h1"}>
                        Get more usable results with Unpinterested!
                    </Typography>
                    <Cta>
                        <Link
                            component={props => <Button {...props} variant="contained" color={"primary"}/>}
                            href={"https://chrome.google.com/webstore/detail/unpinterested/gefaihkenmchjmcpcbpdijpoknfjpbfe"}
                        > Install Unpinterested! now
                        </Link>
                    </Cta>
                </FinalCta>
                <Footer>
                    Crafted by&nbsp;<Link
                    href={"https://github.com/sellomkantjwa/"}>@sellomkantjwa</Link>
                </Footer>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;


