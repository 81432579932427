import styled, {css} from "styled-components";
import {blue, grey, lightGrey, yellow} from "../../colors"

export const Testimonials = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    flex: 3;
    background-color: ${blue};
    color: ${lightGrey};
      .MuiTypography-h1 {
      font-size: 2rem;
    }
    @media screen and (max-width: 640px) {
     padding: 0;
    }
`;

export const TestimonialsDot = styled.div`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 9px;
  background-color: ${grey};
  border-radius: 50%;
  display: inline-block;
  transition:  all 0.25s ease-in;
  ${props => props.active && css`
   background-color: ${yellow};
  `}

  :hover {
  background-color: ${yellow};
  opacity: 0.7;
}`;

export const TestimonialsDots = styled.div`
  padding: 20px 0;
`;

export const TestimonialsStrip = styled.div`
  display: flex;
  background-color: ${blue};
  flex-direction: column;
   color: ${lightGrey};
   @media screen and (max-width: 640px) {
    min-height: 100vh;
    justify-content: center;
  }
`;

export const TestimonialNav = styled.div`
  font-size: 7rem;
  color: ${lightGrey};
  cursor: pointer;
  flex: 1;
  text-align: center;
  :hover {
     ${props => !props.disabled && css`
        transform: scale(1.2);
        `
};
      color: ${grey};
      transition: transform 0.3s ease-in-out;
    }
    color: ${grey};
    transition: transform 0.3s ease-in-out;
    
   @media screen and (max-width: 640px) {
     font-size: 2.5rem;
     flex: 0;
     margin: 1rem;
  }
`;

export const TestimonialsContent = styled.div`
  display: flex;
  background-color: ${blue};
  align-items: center;
  justify-content: center;
}
`;

export const TestimonialsHeader = styled.div`
      margin: 20px;
     .MuiTypography-h1{
      font-size: 2rem;
     }
     
  @media screen and (max-width: 360px) {
   .MuiTypography-h1{
      font-size: 1.5rem;
    }
  }
`;
