import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  font-size: 0.8rem;
  padding: 40px;
  align-items: center;
  justify-content: center;
  img {
   max-width: 20px;
  }
`;
