import styled from "styled-components";


export const PhoneOnly = styled.div`
  display: none;
  
  @media screen and (max-width: 640px) {
     display: initial;
  }
`;

export const PhoneHide = styled.div`
  display: initial;;
  
  @media screen and (max-width: 640px) {
     display: none;
  }
`;
