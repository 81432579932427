import styled, {css} from "styled-components"

export const FeatureSection = styled.div`
  display: flex;
  padding: 75px 0;
  align-items: center;   
  ${props => props.reverse && css`
    flex-direction: row-reverse;
  `}
  }
    
  @media screen and (max-width: 960px) {
     flex-direction: column-reverse;
     padding: 0;
  }
`;


export const FeatureSectionImage = styled.div`
  flex: 1;
  justify-content: center;
  padding: 50px 0;
  box-sizing: border-box;
  text-align: center;
  
  img,video {
    ${props => css`
    max-width: ${props.imageMaxWidth || "400px"}`};
    width: 100%;
    border-radius: 8px 8px 0 0;
    }
}
`;

export const FeatureSectionWriteup = styled.div`
   .MuiTypography-h1 {
        font-size: 2.5rem;
   }
   
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  
  @media screen and (max-width: 960px) {
    .MuiTypography-h1 {
         font-size: 2rem;
     }
     text-align: left;
  }
  
  @media screen and (max-width: 360px) {
        .MuiTypography-h1 {
         font-size: 1.7rem;
         margin: 1rem 0;
     }
  }
  
`;
